/*
This is where you write custom SASS
*/

nav {
    .d-lg-none.py-10px.w-100.bg-white:before {
        content: '';
        position: absolute;
        background: #d8cdc9!important;;
        height: 1px;
        top: 0;
        left: 55px;
        right: 55px;
    }
    
    @media screen and (max-width: 767px) {
        .d-lg-none.py-10px.w-100.bg-white:before {
            left: 30px;
            right: 30px;
        }
    }
    @media screen and (max-width: 576px) {
        .d-lg-none.py-10px.w-100.bg-white:before {
            left: 0px;
            right: 0px;
        }
    }
}
@media screen and (max-width: 991px) {
    .navbar.border-top {
        border-top: none !important;
    }
}

body.interior {
    .card .card-body .form-group .form-control {
        background-color: #fff !important;
        font-size: 17px;
        color: #7d7061;
        border: 1px solid #e0e0e0;
        border-radius: 0;
    }
    .card {
        ::placeholder {
            color: #b6b0a9 !important;
        }
    }
}

section.page-content.financing-page section[style*="margin-left: -25vw"]{
    padding-left: 50px!important;
    padding-right: 50px !important;
    margin-left: 0 !important;
    margin-right: 0  !important;
    @media screen and (max-width: 767px ) {
        padding: 0px !important;
    }
}

.tagline-col {
    background: linear-gradient(to right,  rgba(163,175,72,1) 0%,rgba(101,116,16,1) 100%);
    position: relative;
    p {
        font-family: 'Goudy Old Style';
        font-size: 41px;
        letter-spacing: -1.28px;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        @media screen and (max-width: 1199px) {
            & {
                font-size: 36px;
            }
        }        
        @media screen and (max-width: 767px) {
            & {
                font-size: 30px;
            }
        }
        @media screen and (max-width: 576px) {
            & {
                font-size: 23px;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        border: 5px solid white;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
    }
}

section.blog-section.True {
    background: map-get($theme-colors, light-green) !important;
}
.main-wrap {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, .3); 
}
.top-links {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    font-size: 15px;
    z-index: 1;
    z-index: 9999;

    .dropdown {
        .dropdown-menu::before {
            left: 8px;
        }
    } 

    .bg-top {
        a {
            color: #e3e8b9;
        }
        a:hover {
            color: #fff;
        }
    }
}
.owl-carousel {
    .owl-dots{
        background-color: #fff;
        position: relative;
        margin: 0 auto;
        display: block;
        padding-top: 15px;
    }
}
.main-slide {
    .mod {
        padding-left: 45px;
        p {
            font-family: 'Goudy Old Style';
            float: left;
            min-height: 375px;
            border: 15px solid #fff;
            min-width: 435px;
            padding: 100px 20px 0 50px;
            font-size: 60px;
            font-weight: normal;
            letter-spacing: -1.28px;
            line-height: 65px;

            span {
                font-family: 'Goudy Old Style';
                font-size: 64px;
                font-weight: bold;
                letter-spacing: -1.28px;
            }
        }
    }
}
.owl-theme .owl-dots .owl-dot.active span, 
.owl-theme .owl-dots .owl-dot:hover span {
    background: #b4c15b!important;
}
.owl-theme .owl-dots .owl-dot span {
    &:hover {
        background: #ddd2c5 !important;
        color: #ddd2c5 !important;
    }
    background: #ddd2c5 !important;
    width: 10px!important;
    height: 10px!important;
    margin: 5px 4px!important;
}
.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0 !important;
}
.social {
    
    a {
        &[href*="instagram"] {
            background: linear-gradient(to bottom,  rgba(79,91,213,1) 0%,rgba(150,47,191,1) 25%,rgba(214,41,118,1) 50%,rgba(250,126,30,1) 75%,rgba(254,218,117,1) 100%); 
        }
        &[href*="youtube"] {
            background-color: #FF0000 !important;
        }
        &[href*="facebook"] {
            background-color: #3c5a9a !important;
        }
        &[href*="twitter"] {
            background-color: #18a3f6 !important;
        }
        &[href*="google"] {
            background-color: #dd4c3b !important;
        }
        &[href*="yelp"] {
            background-color: #b00606 !important;
        }
        &[href*="linkedin"] {
            background-color: #0078b5 !important;
        }
        
        @include media-breakpoint-down(md) {
            & {
                width: 34px !important;
                height: 34px !important;
                line-height: 34px !important; 
            }
            
            svg {
                font-size: 14px!important;
                
            } 
        }

    }
    &.fixed-social {
        top: 10%;
        left: 0;
        z-index: 9999;
        box-shadow: 0px 0px 15px 5px rgba($black, .25);
        @media screen and (max-width: 767px) {
            & {
                top: 0 !important;
                left: 0 !important;
            }
        }
    }
    &.mobile-social {
        font-size: 0px;

    }
}

.testimonials {

    h2 {
        color: #b4c15b;
        font-size: 16.5px;
        text-transform: uppercase;
        letter-spacing: 2.56px;
        font-family: 'Lato', sans-serif;
    }
    blockquote {
        color: #7d7061;
        width: 665px;
        margin: 0 auto;
        font-family: 'Goudy Old Style';
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
        line-height: 30px;
    }
    .owl-theme .owl-nav [class*="owl-"] {
        color: #ddd2c5 !important;
        font-size: 40px !important;
        top: 39%;
    }
    cite {
        font-size: 22px;
        color: #9e8c77;
        font-family: 'Goudy Old Style';
        font-weight: normal;
    }
}
.main-form {

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b0a9;
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #b6b0a9;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #b6b0a9;
    }
    .mod1 {
        h2 {
            font-size: 36px;
            letter-spacing: -1.28px;
            color: #7d7061;
            font-weight: normal;
            margin-bottom: 5px;
        }
        p {
            font-size: 15px;
            color: #7d7061;
            line-height: 21px;
        }
        .form-group, .form-row {
            margin-bottom: 8px !important;
            margin-top: 0 !important;
        }
        .form-control {
            font-size: 17px;
            color: #7d7061;
            border: 1px solid #e0e0e0;
            border-radius: 0;
        }
    }
}
.home {
    .main-content {
        padding-top: 15px;
        padding-bottom: 50px;

        h1 {
            font-weight: normal;
            font-size: 42px;
            color: #74615a;
        }
    }
}
.interior {
    .form-control {
        padding: 1.4rem .75rem;
        font-size: 17px;
        color: #7d7061;
        border: 1px solid #e0e0e0;
        border-radius: 0;
    }
    .custom-checkbox {
        .custom-control-label::before {
            border-radius: 0;
        }
    } 
    .btn-light {
        color: #fff;
        background-color: #b4c15b;
        border-color: #b4c15b;
    }
    .btn-light:hover {
        color: #fff;
        background-color: #818d26;
        border-color: #818d26;
    }
    #ctl00_ContentPlaceHolder1_blogWrapper {
        .btn-primary.disabled, .btn-primary:disabled {
            color: #fff;
        }
    } 
    .contact-form {
        .card-body {
            color: #fff;
            background-color: #514940;
            padding: 30px;

            .h3 {
                font-size: 42px;
                border-bottom: 2px solid #847360;
                padding: 0 0 5px 0;
                margin-bottom: 10px;
            }
            .form-group {
                label {
                    display: none;
                }
                ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #fff;
                    opacity: 1; /* Firefox */
                }
                
                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #fff;
                }
                
                ::-ms-input-placeholder { /* Microsoft Edge */
                    color: #fff;
                }
                .form-control {
                    background-color: #ece4db;
                }
                textarea {
                    padding-top: 13px !important;
                }
            }
        }
    }
}
.bg-footer {
    background-color: #2b231d;
    color: #b6a29a;
    p {
        color: #b6a29a;
        font-size: 15px;
        line-height: 23px;
    }
    a {
        color: #b4c15b;
    }
    a:hover {
        color: #818d26;
    }
    .font-size-13 {
        font-size: 13px;
    }
    .font-size-16 {
        font-size: 16px;
    }
}
.btn {
    border: none;
    border-radius: 0;
    color: #fff;
    padding: 15px 40px;
}
.btn:hover {
    color: #fff;
}
.appt-bar {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a3af48+0,657410+100 */
    background: rgb(163,175,72); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(163,175,72,1) 0%, rgba(101,116,16,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(163,175,72,1) 0%,rgba(101,116,16,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(163,175,72,1) 0%,rgba(101,116,16,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a3af48', endColorstr='#657410',GradientType=1 ); /* IE6-9 */
    padding: 30px 0;
    text-align: center;

    p {
        margin: 0;
        font-family: 'Goudy Old Style';
        color: #fff;
        font-size: 32px;
        letter-spacing: -0.64px;

        a {
            color: #fff;
            font-weight: bold;
        }
        a:hover {
            color: #e3e8b9;
        }
    }
}

@media (max-width: 1200px) {
    body {
        background-size: contain;
    }
    .testimonials {
        blockquote {
            width: 100%;
        }
    }
    .main-form .owl-carousel {
        width: 415px;
    }
    .main-slide {
        .mod {
            p {
                min-height: 250px;
                min-width: 310px;
                padding: 60px 20px 0 25px;
                font-size: 45px;
                font-weight: 400;
                line-height: 50px;

                span {
                    font-size: 49px;
                }
            } 
        } 
    } 
}
@media (max-width: 992px) {
    .bg-footer {
        text-align: center;

        .mod3 {
            br {
                display: none;
            }
        }
    }
    .main-form {
        .owl-carousel {
            width: 100%;
            float: none;
        }
        .mod1 {
            padding: 0;
        }
    } 
    .social {
        margin-top: 40px;
    }
    .main-mod {
        margin: 0 auto 0;
    } 
    .main-slide {
        .mod {
            p {
                min-height: 180px;
                min-width: 230px;
                padding: 45px 20px 0 25px;
                font-size: 32px;
                font-weight: 400;
                line-height: 33px;
                border: 10px solid #fff;

                span {
                    font-size: 36px;
                }
            }
        } 
        .panel1, .panel2, .panel3 {
            height: 375px !important;
        }
        .owl-carousel .owl-animated-out, .owl-carousel .owl-stage-outer {
            height: 375px;
        }
    }
    .top-links {
        display: none;
    }
}
@media (max-width: 768px) {
    .appt-bar {
        .space {
            display: none;
        }
        p {
            font-size: 28px;
            line-height: 32px;
        }
    } 
    .testimonials {
        cite {
            font-size: 18px;
        }
    }
    header { 

        #ctl00_ctl10_CompTitle1 {
            font-size: 32px;
            line-height: 32px;
        }
        #ctl00_ctl10_CompTitle2 {
            font-size: 32px;
            line-height: 32px;
        }
        #ctl00_ctl10_CompTitle3 {
            font-size: 22px;
            line-height: 30.5px;
        }
    }
    .home {
        .main-content {
            padding-top: 0;

            h1 {
                font-size: 38px;
            }
        } 
    } 
    .main-slide {
        .slide1 {
            background-position: 95% 17% !important;
        }
        .slide2 {
            background-position: 95% 17% !important;
        }
        .slide3 {
            background-position: 100% 17% !important;
        }
        .mod {
            display: none;
        } 
        .panel1, .panel2, .panel3 {
            height: 250px !important;
        }
        .owl-carousel .owl-animated-out, .owl-carousel .owl-stage-outer {
            height: 250px;
        }
        .owl-dots {
            padding-bottom: 0 !important;
        }
    } 
}
@media (max-width: 576px) {
    footer {
        .bull {
            display: none;
        }
    }
    .interior {
        .btn {
            width: 100%;
        }
        .contact-form {
            .card-body {
                .h3 {
                    font-size: 27px;
                }
                .btn {
                    width: 100%;
                    padding: 15px 0;
                }
            } 
        } 
    } 
    .main-form .mod .owl-theme .owl-nav [class*="owl-"] {
        top: 40% !important;
    }
    .main-form {
        .container {
            padding-top: 50px !important;
        }
        .panel-image {
            iframe {
                height: 300px;
            }
        }
        .owl-carousel .owl-animated-out, .owl-carousel .owl-stage-outer {
            height: 300px;
        }
    }
    .appt-bar {
        padding: 30px 50px;

        p {
            font-size: 24px;
            line-height: 25px;

            a {
                line-height: 33px; 
            }
        }
    } 
    .main-slide {
        .slide1 {
            background-position: 86% 17% !important;
        }
        .slide2 {
            background-position: 78% 17% !important;
        }
        .slide3 {
            background-position: 90% 17% !important;
        }
        .mod {
            display: none;
        } 
        .panel1, .panel2, .panel3 {
            height: 200px !important;
        }
        .owl-carousel .owl-animated-out, .owl-carousel .owl-stage-outer {
            height: 200px;
        }
    } 
}
nav {
    @include media-breakpoint-up(lg) {
        ul#main-menu {
            ul {
                li {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 100%;
                    bottom: 0;
                    left: 0;
                    background: darken($gray-100,5%);
                    transition: .2s ease;
                    z-index: -1;
                }
                    a {
                        &.highlighted {
                            color: $gray-900 !important;
                        }
                        &.current {
                            color: $secondary !important;
                        }
                    }
                }
            }
        li {
            &:hover {
                &:before {
                    right: 0;
                }
            }
        }
        }
    }
}




.phone-dropdown-menu,
.address-dropdown-menu {
  transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: .5rem 0;
  border: none;
  background: transparent;
  margin-top: -1.5rem !important;
  max-width: 100% !important;
  min-width: 16rem !important;
  margin-right: -5px;
  box-shadow: none !important;
  text-transform: uppercase;

  .address-suite,
  .phone-number {
      font-size: $small-font-size;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 25%;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-radius: $border-radius;
    background-color: $gray-100;
    box-shadow: $box-shadow-sm;
    opacity: 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    ul {
      @for $i from 1 to 10 {
        a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
        }
      }
    }
  }


  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
    top: 2rem !important;
    width: 90%;
    max-width: 300px !important;
  }

  ul {
    padding: 0;
    margin: 0;

    a {
      font-size: $font-size-base;
      line-height: 1.4em;
      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 1rem, 0);
      display: block;
      span {
          color:map-get($theme-colors, browncolor)
      }

      .row {
        padding: 1rem * 0.5 1rem * 0.25 1rem * 0.5 1rem;
        position: relative;

        .font-weight-bold {
          font-weight: 400 !important;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 5%;
          bottom: 5%;
          left: 5%;
          right: 5%;
          transition: 0.3s ease;
          border-radius: $border-radius;
          background-color: transparent !important;
        }
      }

      &:hover {

        .row {
          &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}


#quickLinks {
    span.phone-number,
    span.address-suite {
        font-weight: 400 !important;
    }
    .phone-dropdown-menu a , 
    .address-dropdown-menu a {
        transition: .3s ease;
        border-radius: 3px;
        border-bottom: 1px solid #f8f9fa;
    }
}

.address-dropdown-menu ul a .row:before, 
.phone-dropdown-menu ul a .row:before {
    background: darken($gray-100,5%) !important;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    transition: .3s ease !important;
}
.address-dropdown-menu ul a:hover .row:before, 
.phone-dropdown-menu ul a:hover .row:before {
    right: 0;
}




.address-map {
    min-height: 455px;

    .google-maps {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
    }

    iframe {
      //height: 800px;
      //margin: -200px 0;
    min-height: 455px;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {

      &,
      iframe {
        min-height: 300px;
      }
    }
  }

  .address-list-div {
    .container {

      >.row {
        align-items: center;

        .list-group {
          //box-shadow: $shadow-1;
          border: none !important;
          padding: $card-spacer-x !important;
          background-color: $white;
          border-radius: $border-radius;
          z-index: 2;

          >p:not(.h3) {
            font-size: 0.9rem;
            line-height: 1.4;
          }

          .list-group-item {
            background-color: transparent;
            border-color: transparent;
            width: auto !important;
            cursor: default;
            transition: background-color 0.3s ease, border-color 0.3s ease;
            border-radius: $border-radius;

            &:hover {
              background-color: rgba(map-get($theme-colors, secondary), 0.1);
            }

            &:active {
              background-color: rgba(map-get($theme-colors, secondary), 0.2);
            }

            &:first-child {
              padding: 0;

              p {
                font-size: $h3-font-size !important;
                line-height: $headings-line-height !important;
                font-family: $headings-font-family !important;
                margin-bottom: $headings-margin-bottom*2 !important;
              }
            }

            &:not(:first-child) {
              margin-left: -$card-spacer-x/2 !important;
              margin-right: -$card-spacer-x/2 !important;
            }

            span {
              transition: color 0.3s ease 0 !important;

              &.address-suite {
                font-weight: 300;
                font-size: $font-size-base;

                span:last-child {
                  display: none;
                }
              }

              &.city-state-zip {
                font-size: $font-size-sm;
              }
            }

            a {
              opacity: 0;
              visibility: hidden;
              transition: 0.3s ease 0 !important;
              color: map-get($theme-colors, secondary) !important;
            }

            &.active {
              background-color: map-get($theme-colors, primary);

              span {
                color: $white;
              }

              a {
                opacity: 1;
                visibility: visible;
                color: map-get($theme-colors, light) !important;
              }
            }
          }
        }
      }
    }
  }

  section[id*="sectionContact"] {
    .owl-carousel {

        .owl-stage-outer {
          z-index: 1;
      
          &.overflow-visible {
            .owl-stage {
              .owl-item {
                opacity: 1;
                visibility: hidden;
                transition: 0.2s ease;
                
                &.active {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
      
        }
      
        .owl-nav {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 !important;
        }
      
        &:hover {
      
          .owl-prev,
          .owl-next {
            opacity: 1;
          }
        }
      
        @media screen and (max-width: 991px) {
            .owl-prev {
                left: -15px !important;
            }
            .owl-next {
                right: -15px!important;

            }
        }
        .owl-prev,
        .owl-next {
          z-index: 2;
          position: absolute;
          background: rgba(253, 253, 253, 0.75) !important;
          color: #ddd2c5!important;
          top: 50%;
          left: 0;
          padding: 15px !important;
          transform: translate3d(0, -50%, 0);
          opacity: 1;
          transition: 0.4s ease;
          font-size: 26px !important;
      
          &[class*="-next"] {
            right: 0;
            left: auto;
          }
      
          &:hover {
            opacity: 1;
            background: rgba(253, 253, 253, 1) !important;
            color: #ddd2c5!important;
          }
      
          &.always-on {
            opacity: 1;
          }
        }
      
        .owl-dots {
          position: absolute;
          z-index: 2;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          bottom: 0;
          text-align: left;
          opacity: 0;
          transition: 0.4s ease;
      
          &.outside-bottom {
            bottom: auto;
            top: 100%;
          }
      
          &.always-on {
            opacity: 1;
          }
      
          .owl-dot {
            span {
              background: rgba($white, 0.5);
              height: 8px;
              width: 8px;
            }
      
            &.dark span {
              background: rgba(map-get($theme-colors, dark), 0.15);
              height: 8px;
              width: 8px;
            }
      
            &:hover span {
              background: rgba($white, 0.75);
            }
      
            &.active span {
              background: $white;
            }
      
            &.dark:hover span {
              background: rgba(map-get($theme-colors, dark), 0.35);
            }
      
            &.dark.active span {
              background: rgba(map-get($theme-colors, dark), 0.5);
            }
          }
      
          @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
            opacity: 1 !important;
          }
        }
      
        &:hover {
          .owl-dots {
            opacity: 1;
          }
        }
      }
  }