.opacity-100 {
opacity:100 !important;
}

.w-100 {
width:100% !important;
}

.t-0 {
top:0px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.font-size-15px {
font-size:15px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-30px {
margin-right:30px !important;
}

}
 .mr-20px {
margin-right:20px !important;
}

.mr-5px {
margin-right:5px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-30px {
margin-right:30px !important;
}

}
 .mr-20px {
margin-right:20px !important;
}

.mr-5px {
margin-right:5px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-30px {
margin-right:30px !important;
}

}
 .mr-20px {
margin-right:20px !important;
}

.mr-5px {
margin-right:5px !important;
}

.p-0 {
padding:0px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 992px) {.p-lg-0 {
padding:0px !important;
}

}
 .pt-30px {
padding-top:30px !important;
}

@media screen and (min-width: 992px) {.pt-lg-70px {
padding-top:70px !important;
}

}
 .py-25px {
padding-top:25px;padding-bottom:25px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

@media screen and (min-width: 768px) {.pb-md-0 {
padding-bottom:0px !important;
}

}
 .mr-0 {
margin-right:0px !important;
}

.p-0 {
padding:0px !important;
}

.mt-0 {
margin-top:0px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.p-0 {
padding:0px !important;
}

.mt-15px {
margin-top:15px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 992px) {.px-lg-65px {
padding-left:65px;padding-right:65px !important;
}

}
 .p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.p-20px {
padding:20px !important;
}

.mx-2 {
margin-left:2px;margin-right:2px !important;
}

@media screen and (min-width: 576px) {.px-sm-30px {
padding-left:30px;padding-right:30px !important;
}

}
 @media screen and (min-width: 768px) {.px-md-55px {
padding-left:55px;padding-right:55px !important;
}

}
 @media screen and (min-width: 992px) {.px-lg-55px {
padding-left:55px;padding-right:55px !important;
}

}
 @media screen and (min-width: 768px) {.pb-md-0 {
padding-bottom:0px !important;
}

}
 .pb-35px {
padding-bottom:35px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px;padding-right:40px !important;
}

}
 .py-30px {
padding-top:30px;padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px;padding-bottom:0px !important;
}

}
 .font-size-17px {
font-size:17px !important;
}

@media screen and (min-width: 992px) {.py-lg-80px {
padding-top:80px;padding-bottom:80px !important;
}

}
 .py-40px {
padding-top:40px;padding-bottom:40px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px;padding-right:40px !important;
}

}
 .pb-10px {
padding-bottom:10px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px;padding-bottom:100px !important;
}

}
 .py-50px {
padding-top:50px;padding-bottom:50px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px;padding-right:40px !important;
}

}
 .pb-50px {
padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-15px {
padding-bottom:15px !important;
}

.mt-20px {
margin-top:20px !important;
}

.py-25px {
padding-top:25px;padding-bottom:25px !important;
}

.bg-200 {
color:200px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.py-70px {
padding-top:70px;padding-bottom:70px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px;padding-bottom:100px !important;
}

}
 .px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px;padding-right:40px !important;
}

}
 .my-20px {
margin-top:20px;margin-bottom:20px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.bg-200 {
color:200px !important;
}

.my-10px {
margin-top:10px;margin-bottom:10px !important;
}

.font-size-14 {
font-size:14px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.font-size-11 {
font-size:11px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

.px-15px {
padding-left:15px;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px;padding-right:40px !important;
}

}
 .py-35px {
padding-top:35px;padding-bottom:35px !important;
}

@media screen and (min-width: 992px) {.py-lg-70px {
padding-top:70px;padding-bottom:70px !important;
}

}
 .px-0 {
padding-left:0px;padding-right:0px !important;
}

.mt-80px {
margin-top:80px !important;
}

@media screen and (min-width: 992px) {.mt-lg-n10px {
margin-top:-10px !important;
}

}
 @media screen and (min-width: 992px) {.pl-lg-30px {
padding-left:30px !important;
}

}
 .py-25px {
padding-top:25px;padding-bottom:25px !important;
}

.px-50px {
padding-left:50px;padding-right:50px !important;
}

.pb-20px {
padding-bottom:20px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-10px {
margin-left:10px;margin-right:10px !important;
}

.opacity-25 {
opacity:25 !important;
}

.m-0 {
margin:0px !important;
}

.font-size-13 {
font-size:13px !important;
}

.mx-10px {
margin-left:10px;margin-right:10px !important;
}

.mx-10px {
margin-left:10px;margin-right:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-16 {
font-size:16px !important;
}

.font-size-16 {
font-size:16px !important;
}

.mr-10px {
margin-right:10px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

@media screen and (min-width: 992px) {.mt-lg-n22px {
margin-top:-22px !important;
}

}
 .p-0 {
padding:0px !important;
}

.font-size-26px {
font-size:26px !important;
}

